<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 40" fill="none">
		<g clip-path="url(#clip0_414_20195)">
			<path
				d="M19.8529 7.39136C19.0826 5.6188 19.4186 3.71027 20.7949 2.78159C22.451 1.6642 24.9861 2.38847 26.4573 4.39927C26.8148 4.88792 27.0765 5.40757 27.2442 5.93034C28.8049 4.90952 30.7609 5.01787 31.6926 6.20729C32.4518 7.17651 32.305 8.57535 31.4388 9.68724C32.3779 9.54982 33.281 9.82232 33.8065 10.4932C34.6416 11.5593 34.2071 13.2276 32.8361 14.2195C32.1607 14.7081 31.3841 14.9414 30.67 14.925C30.8477 15.8628 30.8832 16.8704 30.7482 17.9376L30.6688 18.5012C30.4779 19.8798 30.2631 21.255 30.0487 22.6279C29.7529 24.5211 29.4579 26.41 29.2272 28.2975C28.6464 33.053 28.6579 35.4792 28.9612 38.0834C29.1013 39.2803 29.2791 40.2673 29.417 40.932C28.9756 41.9882 26.7171 47.1174 21.1706 49.343C20.9467 49.4326 17.3351 50.8437 13.7641 50.4109C13.0972 50.3286 11.8318 50.1112 10.5055 49.8069C9.54145 49.585 8.59072 49.3259 7.66079 49.0345C4.70762 38.9575 5.60007 27.4065 9.58857 18.0045C9.46531 17.9567 9.34149 17.907 9.21728 17.8554C6.4074 16.6874 4.46315 14.9795 4.87467 14.0407C5.28619 13.102 7.89766 13.2878 10.7075 14.4558C10.8702 14.5234 11.0299 14.5928 11.1864 14.6637C11.7938 13.5316 12.4506 12.4421 13.1553 11.4029C14.8934 8.93532 17.3364 7.67285 19.8529 7.39136Z"
				fill="currentColor"
			/>
			<path
				d="M18.7888 23.1507C16.0829 22.3819 14.5683 19.4626 15.4059 16.6302C16.2436 13.7978 19.1161 12.1249 21.822 12.8937C24.5279 13.6625 26.0424 16.5819 25.2048 19.4142C24.3672 22.2466 21.4946 23.9195 18.7888 23.1507Z"
				fill="white"
			/>
			<path
				d="M19.8086 19.7023C18.9202 19.4499 18.4224 18.4932 18.6967 17.5655C18.9711 16.6378 19.9137 16.0904 20.8021 16.3428C21.6906 16.5952 22.1884 17.5519 21.914 18.4796C21.6397 19.4073 20.6971 19.9548 19.8086 19.7023Z"
				fill="black"
			/>
		</g>
		<defs>
			<clipPath id="clip0_414_20195">
				<rect
					width="28.7965"
					height="48.954"
					fill="white"
					transform="matrix(-0.990214 -0.139554 -0.139554 0.990214 35.3462 4.01855)"
				/>
			</clipPath>
		</defs>
	</svg>
</template>
